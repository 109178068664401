<template>
  <b-modal
    id="modal-center"
    @change="hideModal"
    @show="showModal"
    centered
    v-model="isAddNewUserModalActive"
    title="Добавить филиал"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Название"
              rules="required"
            >
              <b-form-group label="Название" label-for="new-branch-name">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="name"
                  id="new-branch-name"
                  placeholder="Название"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Лого"
              rules="required"
            >
              <b-form-group label="Лого" label-for="new-merch-logo">
                <b-form-file
                  v-model="file"
                  @change="handleFileUpload($event)"
                  id="new-merch-logo"
                  :state="errors.length > 0 ? false : null"
                  accept="image/png, image/jpeg, image/tiff"
                  placeholder="Выберите изображение..."
                  drop-placeholder="Drop file here..."
                />
                <div>
                  <small class="text-danger" v-if="imageRequired === 1"
                    >Поле Лого поддерживает только файлы в формате .jpg, .png,
                    .tiff</small
                  >
                </div>
                <div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
                <span class="sm-size"
                  >(Поддерживаются файлы в формате .jpg, .png, .tiff)
                </span>
                <!-- <b-card-text class="my-1">
                Выбранный файл: <strong>{{ file ? file.name : "" }}</strong>
              </b-card-text> -->
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Город"
              rules="required"
            >
              <b-form-group label="Город" label-for="new-merch-city">
                <v-select
                  id="new-merch-city"
                  placeholder="Выберите город"
                  v-model="selectedCity"
                  :state="errors.length > 0 ? false : null"
                  label="nameRu"
                  :options="getCities.cities"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Адрес"
              rules="required"
            >
              <b-form-group label="Адрес" label-for="new-branch-adress">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="adress"
                  id="new-branch-adress"
                  placeholder="Адрес"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <b-form-group label="Локация" label-for="new-branch-location">
              <yandex-map
                id="new-branch-location"
                :settings="mapSettings"
                :coords="baseCoords"
                :zoom="12.5"
                @click="onClick"
                class="new-branch-map"
              >
                <ymap-marker
                  markerId="123"
                  :coords="coords"
                  hint-content="some hint"
                />
              </yandex-map>
              <small v-if="locationRequired === 2" class="text-danger"
                >Поле Локация обязательно для заполнения
              </small>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Мерчант"
              rules="required"
            >
              <b-form-group label="Мерчант" label-for="new-offer-merchant">
                <v-select
                  id="new-offer-merchant"
                  placeholder="Введите название мерчанта"
                  v-model="selectedMerchant"
                  :state="errors.length > 0 ? false : null"
                  label="name"
                  @search="getMerchants"
                  :options="sortedMerchants"
                >
                  <template #no-options="{ search, searching, loading }">
                    Пусто
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer="{ cancel }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        type="reset"
        class="res-w-100"
        @click="cancel()"
      >
        Отмена
      </b-button>
      <b-button
        class="res-mt-2 res-w-100"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click.prevent="handleSubmit"
      >
        <span class="text-nowrap">Добавить</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormFile,
  BRow,
  BCol,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BButton,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import { mapGetters } from "vuex";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    yandexMap,
    ymapMarker,
    BFormFile,
    BRow,
    BCol,
    ToastificationContent,
    BFormTextarea,
    flatPickr,
    BFormRadioGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BModal,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddNewUserModalActive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getCities"]),
  },
  created() {
    this.$store.dispatch("fetchCities");
  },
  data() {
    return {
      required,
      name: null,
      adress: null,
      file: null,
      fileUrl: null,
      selectedCity: null,
      sortedMerchants: null,
      selectedMerchant: null,
      locationRequired: 0,

      imageRequired: 0,

      mapSettings: {
        apiKey: "ff6e2790-0d09-4e1a-b157-adfec8de90d7",
        lang: "ru_RU",
        coordorder: "latlong",
        enterprise: false,
        version: "2.1",
      },
      baseCoords: [41.31136244660736, 69.27975446613132],
      coords: [],
    };
  },
  methods: {
    showModal() {
      if (this.sortedMerchants === null) {
        this.getMerchants();
      } else {
      }
    },

    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (
          success &&
          this.locationRequired === 1 &&
          this.imageRequired === 2
        ) {
          axiosIns
            .post("branches/create", {
              name: this.name,
              address: this.adress,
              logo: this.fileUrl,
              photo: "photo",
              location: {
                latitude: this.coords[0],
                longitude: this.coords[1],
              },
              cityId: this.selectedCity.id,
              vendorId: this.selectedMerchant.id,
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Филиал был успешно создан!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.hideModal();
              this.$emit("refetch-data");
              setTimeout(() => {
                this.resetForm();
              }, 200);
            });
        } else {
          this.locationRequired = 2;
        }
      });
    },
    async handleFileUpload(event) {
      this.file = event.target.files[0];
      if (
        this.file.type != "image/jpg" &&
        this.file.type != "image/jpeg" &&
        this.file.type != "image/png" &&
        this.file.type != "image/tiff"
      ) {
        this.file = "";
        this.imageRequired = 1;
        return false;
      }
      this.imageRequired = 2;
      const formData = new FormData();
      formData.append("logo", this.file);
      const response = await axiosIns.post("image/upload/branch", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.fileUrl = response.data.data.imageUrl;
    },
    async getMerchants(search) {
      const response = await axiosIns
        .get(`vendor/all-active`, {
          params: {
            page: 1,
            size: 20,
            field: "name",
            fieldValue: search,
          },
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTri angleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
      this.sortedMerchants = response.data.data.vendors;
    },
    onClick(e) {
      this.coords = e.get("coords");
      this.locationRequired = 1;
    },
    hideModal() {
      this.$emit("hideModal");
    },
    resetForm() {
      this.name = null;
      this.adress = null;
      this.file = null;
      this.selectedCity = null;
      this.sortedMerchants = null;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
