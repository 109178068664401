var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-center","centered":"","title":"Добавить филиал"},on:{"change":_vm.hideModal,"show":_vm.showModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"res-w-100",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){return cancel()}}},[_vm._v(" Отмена ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"res-mt-2 res-w-100",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Добавить")])])]}}]),model:{value:(_vm.isAddNewUserModalActive),callback:function ($$v) {_vm.isAddNewUserModalActive=$$v},expression:"isAddNewUserModalActive"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название","label-for":"new-branch-name"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-branch-name","placeholder":"Название"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Лого","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Лого","label-for":"new-merch-logo"}},[_c('b-form-file',{attrs:{"id":"new-merch-logo","state":errors.length > 0 ? false : null,"accept":"image/png, image/jpeg, image/tiff","placeholder":"Выберите изображение...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.handleFileUpload($event)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('div',[(_vm.imageRequired === 1)?_c('small',{staticClass:"text-danger"},[_vm._v("Поле Лого поддерживает только файлы в формате .jpg, .png, .tiff")]):_vm._e()]),_c('div',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]),_c('span',{staticClass:"sm-size"},[_vm._v("(Поддерживаются файлы в формате .jpg, .png, .tiff) ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Город","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Город","label-for":"new-merch-city"}},[_c('v-select',{attrs:{"id":"new-merch-city","placeholder":"Выберите город","state":errors.length > 0 ? false : null,"label":"nameRu","options":_vm.getCities.cities},model:{value:(_vm.selectedCity),callback:function ($$v) {_vm.selectedCity=$$v},expression:"selectedCity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Адрес","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Адрес","label-for":"new-branch-adress"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-branch-adress","placeholder":"Адрес"},model:{value:(_vm.adress),callback:function ($$v) {_vm.adress=$$v},expression:"adress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Локация","label-for":"new-branch-location"}},[_c('yandex-map',{staticClass:"new-branch-map",attrs:{"id":"new-branch-location","settings":_vm.mapSettings,"coords":_vm.baseCoords,"zoom":12.5},on:{"click":_vm.onClick}},[_c('ymap-marker',{attrs:{"markerId":"123","coords":_vm.coords,"hint-content":"some hint"}})],1),(_vm.locationRequired === 2)?_c('small',{staticClass:"text-danger"},[_vm._v("Поле Локация обязательно для заполнения ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Мерчант","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Мерчант","label-for":"new-offer-merchant"}},[_c('v-select',{attrs:{"id":"new-offer-merchant","placeholder":"Введите название мерчанта","state":errors.length > 0 ? false : null,"label":"name","options":_vm.sortedMerchants},on:{"search":_vm.getMerchants},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Пусто ")]}}],null,true),model:{value:(_vm.selectedMerchant),callback:function ($$v) {_vm.selectedMerchant=$$v},expression:"selectedMerchant"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }