<template>
  <!-- collapse -->
  <app-collapse id="merchants-list-filter" accordion type="margin" class="">
    <app-collapse-item class="merchants-list-filter-item" title="Фильтры">
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Тег</label>
          <v-select
            v-model="tag"
            :options="getTags"
            placeholder="Выберите тег"
            class="w-100"
            label="businessType"
            @input="$emit('filterByTag', tag)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Категория</label>
          <v-select
            v-model="category"
            :options="getCategories.categories"
            placeholder="Выберите категорию"
            class="w-100"
            label="titleRu"
            @input="$emit('filterByCategory', category)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Статус</label>
          <v-select
            v-model="status"
            placeholder="Выберите Статус"
            :options="statuses"
            class="w-100"
            @input="$emit('filterByStatus', status)"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Дата</label>
          <b-row>
            <b-col md="6">
              <flat-pickr
                v-model="dateFrom"
                class="form-control"
                placeholder="Выберите дату (От)"
                :config="datePickerConfigFrom"
                @on-change="$emit('filterByDateFrom', dateFrom)"
              />
            </b-col>
            <b-col md="6">
              <flat-pickr
                v-model="dateTo"
                class="form-control res-mt-2"
                placeholder="Выберите дату (До)"
                :config="datePickerConfigTo"
                @on-change="$emit('filterByDateTo', dateTo)"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </app-collapse-item>
  </app-collapse>

  <!--/ collapse -->
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { mapGetters, mapActions } from "vuex";
import flatPickr from "vue-flatpickr-component";
import { Russian } from "flatpickr/dist/l10n/ru";
import vSelect from "vue-select";

export default {
  components: {
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  computed: {
    ...mapGetters(["getCategories", "getTags"]),
  },
  data() {
    return {
      category: null,
      selectedDates2: null,
      status: null,
      tag: null,
      statuses: ["ACTIVE", "DELETED", "BLOCKED", "SUSPENDED"],
      dateFrom: null,
      dateTo: null,
      datePickerConfigFrom: {
        altInput: true,
        altFormat: "j F, Y",
        dateFormat: "Y-m-d",
        locale: Russian,
        onChange: function (selectedDates) {
          const asd = selectedDates;
        },
        /* formatDate */
      },
      datePickerConfigTo: {
        altInput: true,
        altFormat: "j F, Y",
        dateFormat: "Y-m-d",
        locale: Russian,
        onChange: function (selectedDates) {
          const asd = selectedDates;
        },
        /* formatDate */
      },
    };
  },
  created() {
    this.fetchCategories();
    this.fetchTags();
  },
  methods: {
    currentDateTime() {
      return moment().format("MMMM Do YYYY, h:mm:ss a");
    },
    ...mapActions(["fetchTags", "fetchCategories"]),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.merchants-list-filter {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}
.merchants-list-filter-item {
  margin-top: 0 !important;
  margin-bottom: 2rem !important;
  border-radius: 0.428rem !important;
}
.merchants-list-filter-item .card-header,
.merchants-list-filter-item .card-body {
  padding-left: 1.5rem !important;
}
</style>
